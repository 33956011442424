<template>
  <v-card>
    <v-card-title>
      <div
        style="width: 100%;"
      >
        <chip
          v-if="isQuickEntry"
          color="primary"
          :close="true"
          :close-icon="icons.mdiClose"
          :icon="icons.mdiInformationOutline"
          :value="'Quick entry is only applicable to wound care practice types and requires a date of service set to the current date.'"
          :label="true"
          icon-color="white"
          class="mb-10 chip"
          style="height: fit-content; width:100%;"
        >
        </chip>
      </div>
      {{ title }}
      <v-spacer></v-spacer>
      <patient-name
        :patient="patient"
      ></patient-name>
    </v-card-title>
    <v-card-subtitle
      class="pb-0"
      style="text-align: right;"
    >
      <patient-info
        :patient="patient"
      ></patient-info>
    </v-card-subtitle>
    <alert
      v-if="patient.no_insurance"
      class="ma-5"
    >
      Patient is uninsured or patient’s insurance does not authorize services.
      Please discuss the need for approval or authorization for future visits with facility staff.
      Contact billing with any questions.
    </alert>
    <v-card-text>
      <v-form
        ref="createEncounterForm"
        v-model="formValid"
        :disabled="!baseDataLoaded"
      >
        <select-box
          v-model="encounter.practice_type_id"
          :items="practiceTypeTemplates"
          label="Practice Type"
          class="mt-5"
          required
          :disabled="isQuickEntry"
          @change="$nextTick(() => {selectVisitType()})"
        >
        </select-box>
        <select-box
          v-model="encounter.place_of_service_id"
          :items="facilities"
          label="Facility"
          required
        >
        </select-box>
        <date-picker
          v-model="encounter.visit_date"
          :disabled="isQuickEntry"
          :min="getMinDate"
          :max="$date().format('YYYY-MM-DD')"
          label="Visit Date"
          required
          @input="$nextTick(() => {selectVisitType()})"
        ></date-picker>
        <radio-buttons
          v-model="encounter.visit_type"
          :items="visitTypes"
          label="Visit Type"
          columns="4"
          disabled
        >
          <help-icon
            title="Visit Type Automatically Selected"
            body="<p>Visit type is automatically selected based on the existence of a previous encounter in the last 36 months for this patient and practice type.</p>"
          ></help-icon>
        </radio-buttons>
        <radio-buttons
          v-model="encounter.visit_location"
          :items="visitLocations"
          label="Visit Location"
          columns="4"
          required
        ></radio-buttons>
        <!-- Start: TXP-136 hide until later release -->
        <checkbox
          v-show="false"
          v-if="!isQuickEntry"
          v-model="encounter.is_deferred_care"
          label="Defer patient care?"
        ></checkbox>
        <text-area
          v-if="!isQuickEntry && encounter.is_deferred_care"
          v-model="encounter.deferred_care_reason"
          label="Enter reason patient care was deferred..."
          class="col-sm-12"
          required
        ></text-area>
        <!-- End: TXP-136 -->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!-- cancel -->
      <btn
        label="Cancel"
        color="secondary"
        :icon="icons.mdiCancel"
        @click="cancelEncounter"
      ></btn>

      <v-spacer></v-spacer>

      <!-- create quick entry -->
      <btn
        v-if="isQuickEntry"
        :disabled="!formValid"
        label="Create Quick Entry"
        :icon="icons.mdiClockPlus"
        @click="createEncounter"
      ></btn>

      <!-- create deferred care encounter -->
      <btn
        v-else-if="encounter.is_deferred_care"
        :disabled="!formValid"
        label="Create Deferred Care Encounter"
        :icon="icons.mdiAccountOff"
        @click="createEncounter"
      ></btn>

      <!-- create encounter -->
      <btn
        v-else
        :disabled="!formValid"
        label="Create Encounter"
        :icon="icons.mdiAccountMultiplePlus"
        @click="createEncounter"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import '@/components/patients/primitives'
import {
  mdiAccountMultiplePlus,
  mdiAccountOff,
  mdiCancel,
  mdiClockPlus,
  mdiClose,
  mdiInformationOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      chip: true,
      formValid: false,
      dos_ack: 0,
      patient: {},
      encounter: {
        visit_type: null,
        visit_location: 'In-person',
        place_of_service_id: null,
        practice_type_id: this.$route.params.practiceTypeId || 1,
        visit_date: this.$date().format('YYYY-MM-DD'),
        is_validated: false,
        is_signed: false,
        is_synced: false,
        is_quick_entry: false,
        is_deferred_care: false,
        deferred_care_reason: null,

        // Not in the DB, for Vue only
        can_be_deleted: true,
      },
      icons: {
        mdiAccountMultiplePlus,
        mdiCancel,
        mdiClose,
        mdiClockPlus,
        mdiAccountOff,
        mdiInformationOutline,
      },
    }
  },
  computed: {
    ...mapGetters('route', ['lastRoute']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('baseData', ['practiceTypeTemplates']),
    ...mapGetters('encounters', ['baseDataLoaded', 'visitLocations', 'visitTypes']),
    isQuickEntry() {
      return this.$route.query
        && String(this.$route.query.quickEntry).toLowerCase() === 'true'
        && this.encounter.practice_type_id === 1
    },
    title() {
      return this.isQuickEntry ? 'Create Quick Entry' : 'Create Encounter'
    },
    getMinDate() {
      if (this.isQuickEntry) return this.$date().format('YYYY-MM-DD')

      return this.$date().subtract(270, 'day').format('YYYY-MM-DD')
    },
  },
  watch: {
    'encounter.visit_date': {
      handler(date, newDate) {
        const todayDate = new Date()

        todayDate.setHours(0, 0, 0, 0)

        const inputDate = new Date(newDate)

        if (inputDate < todayDate && this.isQuickEntry) {
          this.$store.dispatch('notify', { value: "The quick entry visit date must be set to today's date.", color: 'error' })
          this.encounter.visit_date = this.$date().format('YYYY-MM-DD')
        }
      },
    },
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$store.dispatch('notify', { value: 'Search for patient to create an encounter note.' })
      this.$router.push({ name: 'encounter-patient-find' })
    } else {
      this.patient = { ...this.$store.getters['patients/getById'](this.$route.query.id) }
      this.encounter = {
        ...this.encounter,
        created_by_user_id: this.$authUser.id(),
        place_of_service_id: this.$route.params.placeOfServiceId || this.patient.place_of_service_id,
        created: this.$custom.utcNow(),
        modified: this.$custom.utcNow(),
      }
      if (this.patient.is_deceased) {
        this.$store.dispatch('notify', { value: 'Can\'t create encounter (patient deceased).', color: 'error' })
        this.$router.push({ name: 'encounter-patient-find' })
      }
    }

    this.selectVisitType()

    // If coming from the Quick Entry page, create the Quick Entry record automatically
    if (this.isQuickEntry && this.formValid && this.lastRoute.name === 'quick-entry') {
      this.createEncounter()
    }
  },
  methods: {
    formatDate(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY')
    },
    selectVisitType() {
      const previousEncounter = this.$store.getters['encounters/previousEncounterId'](this.patient.id, this.encounter.practice_type_id, this.encounter.visit_date)
      if (!this.encounter.practice_type_id) {
        this.encounter.visit_type = null
      } else if (previousEncounter === false) {
        this.encounter.visit_type = 'New'
      } else {
        this.encounter.visit_type = 'Follow-up'
      }
      this.encounter.is_quick_entry = this.isQuickEntry
    },
    cancelEncounter() {
      if (this.formValid) {
        this.$root.confirm({
          title: 'Cancel?',
          body: 'Are you sure you wish to cancel creating an encounter?',
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('route/back')
          }
        })
      } else {
        this.$store.dispatch('route/back')
      }
    },
    confirmVisitDate(prevDate) {
      // Add 7 days to previous encounter note and if current encounter visit date is 7 days > than previous date trigger return true
      const currDate = this.encounter.visit_date
      let visitDate = prevDate
      visitDate = new Date(visitDate)
      visitDate.setDate(visitDate.getDate() + 7)
      const [preVisitDate] = visitDate.toISOString().split('T')

      return preVisitDate < currDate
    },
    createEncounter() {
      // Used for follow-up visit and confirmVisitDate method
      const previousEncounter = this.$store.getters['encounters/previousEncounter'](this.patient.id, this.encounter.practice_type_id, this.encounter.visit_date)

      // Inform User if pervious visit date is more than 7 days before current visit date
      if (previousEncounter && !this.dos_ack) {
        if (this.confirmVisitDate(previousEncounter.visit_date)) {
          const noteDialog = `The entered date of service is over a week beyond the patient's previous visit date. Please double check that there is not another visit to enter between ${this.formatDate(previousEncounter.visit_date)} and ${this.formatDate(this.encounter.visit_date)}.`
          this.$root.confirm({
            title: 'Notice: Date of Service',
            body: noteDialog,
            large: true,
            cancel: false,
          })

          // Ensures dialogue doesn't appear twice for user
          this.dos_ack = 1
          this.$root.previousPath = this.$route.path

          return
        }
      }

      // Add some required values to the encounter object
      this.encounter.patient_id = this.patient.id

      // Set pay_tier_id
      this.encounter.pay_tier_id = this.$authUser.profile().pay_tier

      if (this.isQuickEntry && this.encounter.practice_type_id === 1) {
        this.encounter.quick_entry_room = null
        this.encounter.quick_entry_notes = null
        this.encounter.quick_entry_notes_to_nurse = null
      }

      // Check to see if it's a duplicate encounter
      const isDuplicate = this.$store.getters['encounters/isDuplicateEncounter'](this.encounter, this.$authUser.id())

      // Verify we're not trying to create an encounter prior to another encounter
      const isPrior = this.$store.getters['encounters/isPriorEncounter'](this.encounter)

      if (isDuplicate) {
        this.$root.confirm({
          title: 'Duplicate Encounter Found, Redirect?',
          body: 'A duplicate encounter was found, do you wish to be redirected to the encounter?',
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('loading', true)
            this.$router.push({
              name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
              query: { id: isDuplicate },
            })
          }
        })
      } else if (isPrior) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Invalid visit date!',
          body: 'You can\'t create an encounter with a visit date prior to another encounter. Encounters must occur in chronological order.',
          cancel: false,
        })
      } else {
        // If follow-up visit, pull data from previous encounter
        if (previousEncounter !== false) {
          // Wound care
          if (this.encounter.practice_type_id === 1) {
            this.encounter = {
              ...this.$lodash.cloneDeep(previousEncounter),
              ...this.encounter,

              // These fields/sections are cleared for follow-up visits
              addendums: [],
              recently_hospitalized: null,
              health_change: null,
              health_change_other: null,
              temperature: null,
              temperature_scale: null,
              heart_rate: null,
              bp_systolic: null,
              bp_diastolic: null,
              respiratory_rate: null,
              encounter_pulse_assessment: {},
              dfu_risk: null,
              dfu_risk_reason: null,
              footwear_evaluation: null,
              footwear_evaluation_reason: null,
              footwear_exam_reason: null,
              footwear_notable_issues: [],
              a1c_level: null,
              encounter_active_wound_infection: {},
              labs: [],
              labs_other: null,
              labs_order_date: null,
              visit_reason: null,
              visit_reason_add_to_note: false,
              is_quick_entry: this.isQuickEntry,
              is_quick_entry_validated: true,
              quick_entry_room: null,
              quick_entry_notes: null,
              quick_entry_notes_to_nurse: null,
              communication_log_id: null,
              communication_log: {},
              general_appearances: [],
              general_appearances_other: null,
              therapeutic_anticoagulant: null,
              mental_status: null,
              assessment_plan: null,
              body_areas: [
                { id: 1, title: 'Head & Face' },
                { id: 2, title: 'Scalp' },
                { id: 3, title: 'Neck' },
                { id: 4, title: 'Back' },
                { id: 9, title: 'Buttocks' },
                { id: 10, title: 'Sacrum' },
                { id: 11, title: 'Coccyx' },
                { id: 12, title: 'Left Ischium' },
                { id: 13, title: 'Right Ischium' },
                { id: 14, title: 'Left Trochanter' },
                { id: 15, title: 'Right Trochanter' },
                { id: 16, title: 'Left Upper Extr' },
                { id: 17, title: 'Right Upper Extr' },
                { id: 18, title: 'Left Lower Extr' },
                { id: 19, title: 'Right Lower Extr' },
                { id: 20, title: 'Left Foot' },
                { id: 21, title: 'Right Foot' },
              ],
              body_areas_notes: null,
              payroll_batch_id: null,
              is_payroll_ignore: 0,
              estimated_payment: null,
              is_revised: 0,
              fax_status_id: 0,
              fax_fail_count: 0,
              pcc_status_id: 0,
              is_manual_upload: 0,
              final_note: null,
              is_imported: 0,
              import_id: null,
              is_reviewed: 0,
              reviewed_time: null,
              reviewed_by_user_id: null,
              signed_by_user_id: null,
              signed_time: null,
            }
          }

          // Psychology
          if (this.encounter.practice_type_id === 3) {
            this.encounter = {
              ...this.$lodash.cloneDeep(previousEncounter),
              ...this.encounter,

              // These fields/sections are cleared for follow-up visits
              psychology: {
                ...this.$lodash.cloneDeep(previousEncounter.psychology),
                appearance: null,
                behaviors: [],
                speeches: [],
                orientation: null,
                memory: null,
                intellect: null,
                concentration: null,
                thought_flows: [],
                insight: null,
                moods: [],
                affects: [],
                hallucinations: null,
                hallucinations_yes: null,
                delusions: null,
                delusions_yes: null,
                homicidal: null,
                homicidal_yes: null,
                suicidal: null,
                suicidal_yes: null,
                judgement: null,
                diagnosis: null,
                previous_diagnosis: null,
                diagnostic_impression: null,
                strengths: [],
                strengths_other: null,
                limitations: [],
                limitations_other: null,
                impaired_functions: [],
                impaired_functions_other: null,
                recommendations: null,
                frequency_of_follow_up_visits: null,
                estimated_duration: null,
                prognosis: null,
                treatment_modification_required_for: null,
                treatment_modification_required_for_yes: null,
              },
            }
          }
        }

        // If deferred care, validate encounter
        if (this.encounter.is_deferred_care) {
          this.encounter.is_validated = true
        }

        // Create the new encounter and set the newly created encounter ID
        this.$store.commit('encounters/addEncounter', this.encounter)
        this.encounter.id = this.$store.getters['encounters/getLastId']

        // If the facility is different and there was a facility acquired wound, remove values
        this.$store.commit('patients/updateFacilityAcquiredWound', {
          id: this.encounter.patient_id,
          facility: this.encounter.place_of_service_id,
        })

        if (this.isQuickEntry) {
          // If quick entry, route to quick entry listing
          this.$store.dispatch('notify', { value: 'Quick entry created successfully.', color: 'success' })
          this.$router.push({
            name: 'quick-entry',
            query: {
              facility: this.$route.params.placeOfServiceId,
              visit_date: this.encounter.visit_date,
            },
          })
        } else if (this.encounter.is_deferred_care) {
          // If deferred care, go to the encounter note
          this.$router.push({
            name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
            query: { id: this.encounter.id },
          })
        } else {
          // Route to the new encounter
          this.$store.dispatch('notify', { value: 'Encounter created successfully.', color: 'success' })
          this.$store.dispatch('loading', true)
          this.$router.push({
            name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
            query: { id: this.encounter.id },
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
  .chip {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    min-height: fit-content;
    padding: 10px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding and width work as expected */
    justify-content: space-around;
  }

</style>
